import { Coding, Extension, DomainResource, Identifier } from 'fhir/r4';

// Refactored from AppSidebar, with some fixes
/**
 * Gets a value from an expected extension on a FHIR resource.
 * @param resource The resource which contains the extension.
 * @param key A case-insensitive slug matching the unique portion of the
 *  extension's url value. For example, the slug "us-core-birthsex" might be
 *  used to match an extension with the url
 *  "https://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex"
 * @param forDisplay If true, the value returned will prioritize the display
 *  value of a coded extension. Otherwise, code will be prioritized.
 * @returns The value of the extension, or null if the extension was not found
 *  or does not have a value.
 */
export function getExtensionValue(resource: DomainResource, key: string, forDisplay?: boolean): string {
  const ext: Extension = resource?.extension?.find(ext => ext.url.toLowerCase().includes(key.toLowerCase()));

  if (ext) {
    const coding: Coding = getCodedValueFromExtension(ext);
    if (coding) {
      if (coding?.code && !(forDisplay && coding.display)) {
        return coding.code;
      }
      return coding?.display;
    } else {
      if (ext.extension?.length > 0) {
        return ext.extension.find(ext => ext.url === 'text')?.valueString;
      } else if (ext.valueString) {
        return ext.valueString;
      } else if (ext.valueCode) {
        return ext.valueCode;
      }
    }
  }

  return null;
}

/**
 * Gets a coded value from an extension.
 * @param ext The extension
 * @returns The coded value of the extension, or null if the extension is not
 *  coded.
 */
function getCodedValueFromExtension(ext: Extension): Coding {
  if (ext.extension?.length > 0) {
    const ombCategory = ext.extension.find(ext => ext.url === 'ombCategory');
    if (ombCategory) {
      return ombCategory.valueCoding;
    }
  } else if (ext.valueCodeableConcept && ext.valueCodeableConcept.coding.length > 0) {
    return ext.valueCodeableConcept.coding[0];
  } else if (ext.valueCoding) {
    return ext.valueCoding;
  }
  return null;
}

export function getIdentifierValue(identifier: string | Identifier): string {
  if (typeof identifier === "string") {
    return identifier;
  }
  return identifier?.value;
}
