
import Hl7Service from '@/services/hl7Service';
import { defineComponent } from '@vue/runtime-core';
import { PatientReportType } from '@/models/foto/patientReportType';
import { inject } from 'vue';
import AdditionalItemTable from './AdditionalItemTable.vue';
import Artifact from '@/models/foto/artifact';
import SummaryChart from './SummaryChart.vue';
import Surveys from './Surveys.vue';
import SurveyLaunch from '@/components/form/SurveyLaunchForm.vue';
import Patient from '@/models/foto/patient';
import { FunctionalAssessment } from '@/models/foto/functionalAssessment';
import { DocumentReference, Identifier } from 'fhir/r4';
import { AssessmentAttribute } from '@/models/foto/assessmentAttribute';
import ToastService from '@/services/toastService';

export default defineComponent({
    name: 'ArtifactDetail',
    components: {
        AdditionalItemTable,
        SummaryChart,
        Surveys,
        SurveyLaunch
    },
    setup: function(): any {
        const store = inject('store');
        const patient = (store as any).GetStateValue('FotoPatient');
        const episode = (store as any).GetStateValue('SelectedEpisode');

        return {
            store,
            patient,
            episode
        };
    },
    props: {
        artifact: Artifact,
    },
    data: function(): any {
        return {
            artifactVisitValue: 0,
            updatingVisitNumber: false,
            isLoadingEpisodeOptionSelection: false,
        };
    },
    computed: {
        panelBodyHeader(){
            let header = `${this.getformattedDate(this.artifact.SurveyDateTimeCompleted)} - ${PatientReportType[this.artifact.ReportType].toString()} Summary - Completed`;

            if([0, 1].includes(this.artifact.ReportType) && this.artifact.FunctionalAssessments.length > 0 && this.primaryAssessment){
                const scoreArrLen = this.primaryAssessment.FunctionalScoreSummaries?.length;
                header += ` - ${this.primaryAssessment?.FunctionalScoreSummaries[scoreArrLen - 1]?.FunctionalScore}`;
            }
            else if(this.primaryAssessment)
                header += ' - N/A';

            return header;
        },
        primaryAssessment(){
            if (this.artifact.FunctionalAssessments.length > 0){
                const primaryMeasure = this.artifact.FunctionalAssessments.filter((fa: FunctionalAssessment) => {
                    return fa.AssessmentAttributes.filter((aa: AssessmentAttribute) => aa.Attribute.toLowerCase() === "isprimarymeasure" && aa.Value.toLowerCase() === "true")[0];
                })[0];

                const ocYourWay = this.artifact.FunctionalAssessments.filter((fa: FunctionalAssessment) => {
                    return fa.AssessmentAttributes.filter((aa: AssessmentAttribute) => ["isderivedfromcrossscore", "isderivedfromcrosswalk"].includes(aa.Attribute.toLowerCase()) && aa.Value.toLowerCase() === "true")[0];
                })[0];

                return ocYourWay ? ocYourWay : primaryMeasure;
            }
            else
                return null;
        },
        limitedAssessmentData(){
            const functionalAssessments = this.artifact.FunctionalAssessments.filter(fa => !fa.AssessmentAttributes.some(a => a.Attribute == 'IsPrimaryMeasure' && a.Value == 'false') && fa.StagingInfo != null);
            const scoreLabels: Array<string> = [];
            const data: any = { 
                headers: [],
                data: []
            };                

            functionalAssessments.forEach(fa => {
                fa.FunctionalScoreSummaries.forEach(fss => {
                    if(fss.Label && !scoreLabels.some(sl => sl == fss.Label)){
                        scoreLabels.push(fss.Label);
                    }
                })
            })

            scoreLabels.push("FOTO Intake");

            scoreLabels.forEach(sl => {
                const object: any = {
                    label: sl,
                    scores: []
                };

                if(sl == "FOTO Intake"){
                    functionalAssessments.forEach(a => {
                        object.scores.push({
                            title: a.Descriptor,
                            score: a.RiskAdjustedStatisticalFOTO == 0 ? "--" : a.RiskAdjustedStatisticalFOTO
                        })
                    })
                }
                else{
                    const assessments = functionalAssessments.filter(fa => fa.FunctionalScoreSummaries.some(fss => fss.Label == sl));

                    assessments.forEach(a => {
                        const score = a.FunctionalScoreSummaries.filter(fss => fss.Label == sl)[0];

                        if(!data.headers.some(h => h == a.Descriptor)){
                            data.headers.push(a.Descriptor);
                        }

                        object.scores.push({
                            title: a.Descriptor,
                            score: score.FunctionalScore
                        })
                    })
                }
                
                data.data.push(object);
            })

            return data;
        },
        menuItems(){
            const items = [
                {
                    label: 'View',
                    icon: 'pi pi-eye',
                    command: async () => { 
                        this.isLoadingEpisodeOptionSelection = true;
                        await this.viewReport();
                        this.isLoadingEpisodeOptionSelection = false;
                    },
                },
                {
                    label: 'Download',
                    icon: 'pi pi-file-pdf',
                    command: async () => { 
                        this.isLoadingEpisodeOptionSelection = true;
                        await this.downloadReport();
                        this.isLoadingEpisodeOptionSelection = false;
                    },
                }
            ];

            if(this.store.Context.PractitionerLaunch){
                items.unshift({
                    label: 'Upload to Media Manager',
                    icon: 'pi pi-cloud-upload',
                    command: async () => { 
                        this.isLoadingEpisodeOptionSelection = true;
                        await this.uploadReport();
                        this.isLoadingEpisodeOptionSelection = false;
                    },
                });

                items.unshift({
                    label: 'Upload to Chart Review',
                    icon: 'pi pi-upload',
                    command: async () => { 
                        this.isLoadingEpisodeOptionSelection = true;
                        await this.uploadToPatientNotes();
                        this.isLoadingEpisodeOptionSelection = false;
                    },
                });
            }

            return items;
        }
    },
    methods: {
        getAttribute(attrName: string){
            const predictedChange = this.primaryAssessment.AssessmentAttributes.filter((aa: AssessmentAttribute) => aa.Attribute.toLowerCase() === attrName.toLowerCase());

            if(predictedChange[0])
                return predictedChange[0];

            return null;
        },
        getformattedDate(datetime: string) {
            return new Date(datetime).toISOString().split('T')[0].replace(/-/g, '/');
        },
        toggleArtifactVisitNumOp(event: any) {
            this.$refs[`artifactVisitNumOp-${this.artifact.ReportId}`].toggle(event);
        },
        async updateVisitNumber(event: any) {
            try{
                if (this.artifact.Visit !== this.artifactVisitValue){
                    this.store.AddLog(`Updating visit for report ${this.artifact.ReportId} with value ${this.artifactVisitValue}`);
                    this.updatingVisitNumber = true;
                    this.$Progress.start();
    
                    const update_response = await this.store.Artifact.SetVisit(this.artifact.ReportId, this.artifactVisitValue);
    
                    if(update_response.successful)
                        ToastService.Success("Visit number updated successfully");
                    else
                        ToastService.Error(`Error updating visit number: ${update_response.errorMessage}`);
    
                    this.$Progress.finish();
                    this.updatingVisitNumber = false;
                }
                    
                this.$refs[`artifactVisitNumOp-${this.artifact.ReportId}`].toggle(event);
            }
            catch(ex: any){
                this.$Progress.finish();
                this.updatingVisitNumber = false;
            }
        },
        async uploadReport() {
            try{
                const reportTypeTxt = PatientReportType[this.artifact.ReportType];

                this.store.AddLog('Uploading report to mychart');

                this.$Progress.start();
                const report = await this.store.Artifact.GetReport(this.artifact.ReportId);
                const therapist = this.episode.TherapistName;
                const bodyPartText = this.episode.BodyPartText
                const patient = this.store.Fhir.State.Patient;
                const encounter = this.store.Fhir.State.Encounter;

                // it looks like 1.2.840.114350.13.565.2.7.5.737384.14 is some variant of EPI. likely MRN in EPIC's system.
                let mrn = patient.identifier.filter((i: Identifier) => i.system?.endsWith("737384.14"))[0]?.value;
                
                if(!mrn)
                    mrn = patient.identifier.filter((i: Identifier) => i.type?.text?.toUpperCase().includes("MR"))[0]?.value;

                if(!mrn)
                    mrn = patient.identifier.filter((i: Identifier) => i.type?.text?.toUpperCase().includes("STAR"))[0]?.value;

                if(!mrn)
                    mrn = patient.identifier.filter((i: Identifier) => i.type?.text?.toUpperCase().includes("EPI"))[0]?.value;

                const pFName = patient.name[0]?.given[0];
                const pLName = patient.name[0]?.family;

                const encounterId = encounter.identifier.filter((i: Identifier) => i.system.endsWith('698084.8'))[0]?.value; //csn
                const externalId = `${this.artifact.ReportId}_${reportTypeTxt.toLowerCase()}`;
                const base64 = Buffer.from(report).toString('base64');

                const response = await Hl7Service.Mdm.Send(mrn, pFName, pLName, therapist, encounterId, externalId, `FOTO.${reportTypeTxt}.${bodyPartText}`, base64, this.artifact);

                if(response.successful)
                    ToastService.Success("Upload Successful!");
                else
                {
                    ToastService.Error(`An error has occurred. Message: ${response.errorMessage}`);
                    throw response;
                }

                this.$Progress.finish();
            }
            catch(e){
                this.$Progress.finish();
                Promise.reject(e);
            }
        },
        async uploadToPatientNotes(){
            try{
                this.$Progress.start();

                let content = "Auto-generated from Net Health Systems";
                const patient = this.store.Fhir.State.Patient.id;
                const encounter = this.store.Fhir.State.Encounter.id;
                const surveys = document.querySelectorAll(`.survey_${this.artifact.ReportId}_${this.artifact.ReportType}`);
                const fsm = document.querySelector(`#fsm_${this.artifact.ReportId}_${this.artifact.ReportType}`);
                const rac = document.querySelector(`#rac_${this.artifact.ReportId}_${this.artifact.ReportType}`);
                const aid = document.querySelector(`#aid_${this.artifact.ReportId}_${this.artifact.ReportType}`);

                if(fsm) content += ' \n ' + (fsm as any).innerText;
                if(rac) content += ' \n ' + (rac as any).innerText;
                if(aid) content += ' \n ' + (aid as any).innerText;

                surveys.forEach((survey) => content += ' \n \n ' + (survey as any).innerText);
                
                const base64 = Buffer.from(content).toString('base64');
                const docRef: DocumentReference = ({
                    resourceType: "DocumentReference",
                    status: "current",
                    docStatus: "final",
                    type: {
                        coding: [{
                            system: "http://loinc.org",
                            code: "11488-4",
                            display: "Consultation Note"
                        }],
                        text: "Consultation Note"
                    },
                    author: [{
                        reference: "Auto-generated from Net Health Systems",
                        display: "Auto-generated from Net Health Systems"
                    }],
                    subject: {
                        reference: `Patient/${patient}`    
                    },
                    content: [{
                        attachment: {
                            contentType: "text/plain",
                            data: base64
                        }
                    }],
                    context: {
                        encounter: [{
                            reference: `Encounter/${encounter}`
                        }]
                    }
                });

                this.store.AddLog('Uploading DocumentReference information to patient notes.', docRef);

                const response = await this.store.Fhir.Create(docRef);

                if(response.successful)
                    ToastService.Success("Upload Successful!");
                else
                {
                    ToastService.Error(`An error has occurred. Message: ${response.errorMessage}`);
                    throw response;
                }

                this.$Progress.finish();
            }
            catch(ex){
                this.$Progress.finish();
                Promise.reject(ex);
            }
        },
        async downloadReport() {
            try{
                this.store.AddLog(`Downloading report ${this.artifact.ReportId}`);

                this.$Progress.start();
                const report = await this.store.Artifact.GetReport(this.artifact.ReportId);
                const blob = new Blob([new Uint8Array(report)], { type: 'application/pdf' });
                const link = document.createElement('a');
                const reportType = PatientReportType[this.artifact.ReportType];
                const pat: Patient = this.store.GetStateValue('FotoPatient') as Patient;
                const dateCompleted = new Date(this.artifact.SurveyDateTimeCompleted);
                const dateStarted = new Date(this.artifact.SurveyDateTimeStarted);
                const dateStr = dateCompleted.getFullYear() !== 1 
                    ? `${dateCompleted.getMonth() + 1}${dateCompleted.getDate()}${dateCompleted.getFullYear()}`
                    : `${dateStarted.getMonth() + 1}${dateStarted.getDate()}${dateStarted.getFullYear()}`;
    
                link.href = URL.createObjectURL(blob);
                link.download = `${pat.Alias}_${reportType}_${dateStr}.pdf`;
                link.click();
                this.$Progress.finish();
            }
            catch(e){
                this.$Progress.finish();
                Promise.reject(e);
            }
        },
        async viewReport() {
            try{
                this.store.AddLog(`Viewing report ${this.artifact.ReportId}`);
                this.$Progress.start();
                const report = await this.store.Artifact.GetReport(this.artifact.ReportId);
                const blob = new Blob([new Uint8Array(report)], { type: 'application/pdf' });
                const tab = window.open('about:blank');
    
                console.log(tab);
                tab.location = URL.createObjectURL(blob) as any;
                console.log(tab.location);

                tab.focus();
                this.$Progress.finish();
            }
            catch(e){
                this.$Progress.finish();
                Promise.reject(e);
            }
        },
        toggle(event: any) {
            const ref: any = this.$refs[`artifact_report_menu_${this.artifact.ReportId}`];
            ref.toggle(event);
            event.stopPropagation();
        },
    },
});
